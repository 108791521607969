import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom"
import axiosInstance from "../services/api";
import { format, parseISO } from 'date-fns'
import Rating from "react-rating";
//@ts-ignore
import ReactStars from "react-rating-stars-component";
import star from './star.png'
export interface review {
    createdAt: string;
    customeruserId: string;
    id: string;
    modifiedAt: string;
    rating: number;
    review: string;
    vendorshopId: string;
}
function Reviews() {
    const params = useParams<{ shopId: string }>();
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [isReviewAdded, setIsReviewAdded] = useState(true)
    const [review, setReview] = useState('');
    const [userRating, setUserRating] = useState(0);
    const queryClient = useQueryClient();
    useEffect(()=>{
        if(localStorage.getItem('accesstoken')){
            setIsLoggedIn(true)
        } else{
            setIsLoggedIn(false)
        }
    },[])
    const reviewQuery = useQuery(['review', params.shopId], async () => {
        const { data } = await axiosInstance.get<review[]>(`/vendorshops/${params.shopId}/shop-rating-reviews`)
        return data.map(el => ({ ...el, modifiedAt: format(parseISO(el.modifiedAt), 'dd MMM, yyyy') }))
    }, {
        onSuccess: (data) => {
            const customerReview = data.filter(el => el.customeruserId === localStorage.getItem('c_id'))
            if (customerReview.length > 0) {
                setIsReviewAdded(true)
            } else {
                setIsReviewAdded(false);
            }
        },
        onError: (error) => {
            console.log(error)
        },
        retry: 0,
        refetchOnWindowFocus: false
    })
    const addReview = useMutation(() => axiosInstance.post(`/customer-users/${localStorage.getItem('c_id')}/shop-rating-reviews`, {
        rating: userRating,
        review: review,
        vendorshopId: params.shopId,
        customeruserId: localStorage.getItem('c_id')

    }), {
        onSuccess: (data) => {
            console.log(data)
            queryClient.invalidateQueries(['review', params.shopId])
        },
        onError: (error) => {
            console.log(error)
        }
    })
    return (<div>
        <section className={"mt-10 container mx-auto px-28"}>
            <h3 className={"font-semibold text-xl mb-5"}>Reviews</h3>
            {
                isLoggedIn && <>
                    {
                        !isReviewAdded && <form action="">

                            <div className="mb-5">
                            <ReactStars
                                                    count={5}
                                                    size={24}
                                                    onChange={(rating:number)=>setUserRating(rating)}
                                                    activeColor="#ffd700"
                                                />

                                {/* <Rating
                                    emptySymbol={<img src={star} className="icon h-[20px] saturate-0" />}
                                    fullSymbol={<img src={star} className="icon h-[20px] " />}
                                    onChange={(rate) => setUserRating(rate)}
                                /> */}
                            </div>
                            <textarea className="border border-slate-600 w-full rounded-md p-3" rows={3} value={review} onChange={(event) => setReview(event.target.value)} />
                            <button className="bg-sky-700 text-white p-3 rounded-md mt-3" type="button" onClick={() => addReview.mutate()}>Add Review</button>
                        </form>

                    }

                </>
            }{
                reviewQuery?.isLoading ? <>Loading</> :
                    reviewQuery?.isError ? <>Failed to load data</> :
                        <>
                            {reviewQuery?.data?.map(el => <React.Fragment key={el.id}>
                                <div className="p-5 border border-slate-300 rounded-md my-3">
                                    <div className="flex items-baseline">
                                    <ReactStars
                                                    count={5}
                                                    size={24}
                                                    value={el.rating}
                                                    edit={false}
                                                    activeColor="#ffd700"
                                                />
                                        <p className="ml-3 text-sm text-slate-500">{el.modifiedAt}</p>
                                    </div>

                                    <h3 className="text-lg mt-5">{el.review}</h3>
                                </div>
                            </React.Fragment>)}
                        </>
            }

        </section>
    </div>)
}
export default Reviews


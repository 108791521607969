import React from "react";
import shopImage from "../images/online-shop.png";
const BusinessOwnerBanner = () => {
    return (<section className={"container mx-auto px-28 mt-10 "}>
        <div className={"bg-blue-50 rounded-md py-10 px-20 flex justify-between"}>

            <div className={""}>
                <p className={"text-3xl mb-2"}>Are you A Business Owner?</p>
                <p className={"text-xl mb-5"}>Get yourself listed on Lambdamart</p>
                <a  href="https://vendor.lambdamart.in" target="_blank" className={"bg-cyan-700 text-white px-3 py-2 rounded-md"}>Get Started</a>
            </div>
            <div>
                <img src={shopImage} alt="shop" style={{height:"150px"}}/>
            </div>
        </div>
    </section>)
}
export default BusinessOwnerBanner;
import React from "react";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";
export interface IStarProps {
    value: number;
}
const SearchStar = ({value}:IStarProps)=>{
    const emptyStarList = [<AiOutlineStar/>,<AiOutlineStar/>,<AiOutlineStar/>,<AiOutlineStar/>,<AiOutlineStar/>];
    const filledStarList = [<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>];
    const modifiedStars = [...filledStarList.slice(5-value,5) ,...emptyStarList.slice(value,5)]
    return(
        <li className={"text-xl flex items-center mb-2"}>
            <input className={"block"} type="radio" name={"rating"} value={value}/><label className={"ml-2 inline-flex text-amber-500"}>{
            modifiedStars.map(el=><span>{el}</span>)
        }</label>
            <span className={"block ml-2 text-sm"}>& up</span>
        </li>
    )
}
export default SearchStar;
import React from 'react';

import './App.css';
import {Route, Routes} from "react-router-dom";
import Landing from "./landing/Landing";
import SearchResults from "./search-result/SearchResults";
import ShopResult from "./shop-result/ShopResult";
import Reviews from './shop-result/Reviews';
import Products from './shop-result/Products';
import About from './shop-result/About';
import ProductDetails from './shop-result/ProductDetails';


function App() {
  return (
    <div>
      <Routes>
          <Route path="/" element={<Landing/>} />
          <Route path="/search" element={<SearchResults/>} />
          <Route path="/shop/:shopId" element={<ShopResult/>} >
            <Route index element={<About/>} />
            <Route path="reviews" element={<Reviews/>} />
            <Route path="products" element={<Products/>} />
            <Route path="products/:productId" element={<ProductDetails/>} />
            </Route>
          <Route  path={"*"} element={<> no results</>} />
      </Routes>
     
    </div>
  );
}

export default App;

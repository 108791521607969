import React, { useEffect, useState } from "react";
import {FiSearch} from "react-icons/fi";
import {Link, useNavigate} from "react-router-dom";
import UserLogin from "../auth/UserLogin";
import MainSearch from "../main-search/MainSearch";
const Header = () => {
    const [showLogin, setShowLogin] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(()=>{
        if(localStorage.getItem('accesstoken')){
            setIsLoggedIn(true)
        } else{
            setIsLoggedIn(false)
        }
    },[])
    const logout =()=>{
        localStorage.removeItem('accesstoken');
        localStorage.removeItem('c_id');
        window.location.reload()
    }
  return (
 <div className={"mx-auto px-10 border-b z-100 bg-white"}>
     <div className={"flex justify-between items-center h-24"}>
         <Link to={'/'} className={"text-xl  font-bold"}>
             Lambdamart
         </Link>
         <MainSearch/>
         {
                isLoggedIn ? <div>
                    <button className={"text-sky-600 font-bold px-3 py-2 rounded-md"} onClick={logout}>Logout</button>
                </div> :  <div className={"flex"}>
             <button className={"text-cyan-700 font-bold px-3 py-2 rounded-md"} onClick={() => setShowLogin(true)}>Login</button>
             <button className={"border-cyan-700 font-bold border-[1px] ml-3 text-cyan-700 px-3 py-2 rounded-md"} onClick={() => setShowLogin(true)}>Signup</button>
         </div>
            }
        
     </div>
     {showLogin && <UserLogin closeLogin={setShowLogin} />}
 </div>
  );
};
export default Header;
import React, { useEffect, useState } from 'react';
import Signup from '../auth/Signup';
import UserLogin from '../auth/UserLogin';
const Header = () => {
    const [showLogin, setShowLogin] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(()=>{
        if(localStorage.getItem('accesstoken')){
            setIsLoggedIn(true)
        } else{
            setIsLoggedIn(false)
        }
    },[])
    const logout =()=>{
        localStorage.removeItem('accesstoken');
        window.location.reload()
    }
    return (<div className={""}>
        {/*className={"container mx-auto px-28 my-20 "}*/}
        <div className={"flex justify-between container mx-auto px-28 py-3 "}>
            <div className={"flex"}>
                <a className={"text-white font-bold block"} href="https://vendor.lambdamart.in" target="_blank">For Business</a>
            </div>
            {
                isLoggedIn ? <div>
                    <button className={"text-white font-bold px-3 py-2 rounded-md"} onClick={logout}>Logout</button>
                </div> : <div>
                    <button className={"text-white font-bold px-3 py-2 rounded-md"} onClick={() => setShowLogin(true)}>Login</button>
                    <button className={"border-white font-bold border-[1px] ml-3 text-white px-3 py-2 rounded-md"} onClick={() => setShowLogin(true)}>Signup</button>
                </div>
            }

        </div>
        {showLogin && <UserLogin closeLogin={setShowLogin} />}
        {/* <Signup/> */}
    </div>)
}
export default Header;
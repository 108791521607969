import axios, { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useMutation } from "react-query"
import axiosInstance from "../services/api";
type sendOTPForm = {
    mobileNum: string,
};
type verifyOTPForm = {
    mobileNum: string,
    otp: string;
};
type userForm = {
    name: string,
};
function UserLogin(props:{closeLogin:any}) {
    const { register, handleSubmit, watch, formState: { errors } } = useForm<sendOTPForm>();
    const otpForm = useForm<verifyOTPForm>();
    const signupForm = useForm<userForm>();
    const [isOTPSent, setIsOTPSent] = useState(false);
    const [showErrrorAlert, setShowErrorAlert] = useState('');
    const [showOTPSent, setShowOTPSent] = useState(false);
    const [signup, showSignUp] = useState(false)
    const [phone, setPhone] = useState('');
    const getOTP = useMutation((mobileNum: string) => axiosInstance.post(`/customer/sent-otp`, {
        mobileNum: `91${mobileNum}`
    }), {
        onSuccess: (data, variables, context) => {
            setIsOTPSent(true)
            setShowOTPSent(true)
            setShowErrorAlert('')
            otpForm.setValue('mobileNum', variables)
        },
        onError: (error) => {
            console.log(error)
        }
    });
    const signupQuery = useMutation((data:{mobileNum:string, name:string}) => axiosInstance.post(`/customer/signup`, {
        mobileNum: `91${data.mobileNum}`,
        name:data.name
    }), {
        onSuccess: (data, variables, context) => {
           console.log(data)
           localStorage.setItem('accesstoken', data.data.tokens.accessToken)
           localStorage.setItem('c_id', data.data.id)
           window.location.reload();
        },
        onError: (error) => {
            console.log(error)
        }
    });
    const verifyOTPQuery = useMutation((data: { mobileNum: string, otp: string }) => axiosInstance.post(`/customer/verify-otp`, {
        mobileNum: `91${data.mobileNum}`,
        otp: data.otp
    }), {
        onSuccess: (data: AxiosResponse<{id:string, tokens:{accessToken:string}, data: { isNew: boolean } }>, variables, context) => {
            if (data.data.data.isNew) {
                console.log("new user")
                setPhone(variables.mobileNum)
                showSignUp(true);
            } else {
                console.log(data.data)
                localStorage.setItem('accesstoken', data.data.tokens.accessToken)
                localStorage.setItem('c_id', data.data.id)
                window.location.reload();
            }

        },
        onError: (error: AxiosError) => {
            setShowErrorAlert(`${error?.response?.data?.error?.message}`)
        }, onSettled: () => {
            setShowOTPSent(false)
        }
    });
    const onSubmit: SubmitHandler<sendOTPForm> = data => {
        getOTP.mutate(data.mobileNum)
    };
    const verifyOTP: SubmitHandler<verifyOTPForm> = data => {
        verifyOTPQuery.mutate(data)
    }
    const createAccount: SubmitHandler<userForm> = data => {
        console.log('data', data)
        signupQuery.mutate({...data, mobileNum: phone})
    }
    return (<div className="h-screen w-full bg-slate-600 overflow-hidden fixed top-0 left-0 bg-opacity-50 h-screen flex items-center justify-center z-10">
        <div className="bg-white shadow p-5 rounded-md w-[500px]">
            <div>
                <div className="flex w-full mb-5 justify-center">
                    <div className="text-3xl text-sky-600 font-bold text-center">
                        Lambdamart
                    </div>
                    <button className="h-9 w-9 bg-slate-100 text-slate-600 ml-auto rounded-full justify-end" onClick={()=>props.closeLogin()}>X</button>
                </div>
                {!isOTPSent && <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label htmlFor="" className="w-full block mb-3">Phone Number*</label>
                        <input type="text" className="bg-slate-100 border-slate-200 border p-3 rounded w-full block" maxLength={10}
                            {...register("mobileNum")}
                        />
                    </div>
                    <div>
                        <button className="bg-sky-600 text-white p-3 rounded mt-3 w-full">Send OTP</button>
                    </div>
                </form>}
                {isOTPSent && !signup && <form onSubmit={otpForm.handleSubmit(verifyOTP)}>
                    {showErrrorAlert && <div className="bg-red-100 text-red-600 p-3 mb-5 rounded-md">
                        {showErrrorAlert}
                    </div>}
                    {showOTPSent && <div className="bg-green-100 text-green-600 p-3 mb-5 rounded-md">
                        OTP sent successfully
                    </div>}


                    <div>
                        <label htmlFor="" className="w-full block mb-3">Phone Number*</label>
                        <input type="text" className="bg-slate-100 border-slate-200 border p-3 rounded w-full block" disabled maxLength={10}
                            {...otpForm.register("mobileNum")}
                        />
                    </div>
                    <div>
                        <label htmlFor="" className="w-full block my-3">OTP*</label>
                        <input type="text" className="bg-slate-100 border-slate-200 border p-3 rounded w-full block" maxLength={6}
                            {...otpForm.register("otp")}
                        />
                    </div>
                    <div>
                        <button className="bg-sky-600 text-white p-3 rounded mt-3 w-full font-semibold">Verify OTP</button>
                        <button className="text-sky-600 p-3 rounded mt-3 w-full font-semibold" type="button" onClick={() => getOTP.mutate(otpForm.getValues('mobileNum'))}>Resend OTP</button>
                    </div>
                </form>}
                {signup && <form onSubmit={signupForm.handleSubmit(createAccount)}>
                    <div className="bg-green-100 text-green-600 p-3 mb-5 rounded-md">
                        OTP verified successfully
                    </div>
                    <div>
                        <label htmlFor="" className="w-full block mb-3">Name*</label>
                        <input type="text" className="bg-slate-100 border-slate-200 border p-3 rounded w-full block" maxLength={100}
                            {...signupForm.register("name")}
                        />
                    </div>
                    <div>
                        <button className="bg-sky-600 text-white p-3 rounded mt-3 w-full">Sign up</button>
                    </div>
                </form>}
            </div>
        </div>
    </div>)
}
export default UserLogin
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../services/api";
import { IProduct } from "./Products";
import {BiArrowBack} from "react-icons/bi"
function ProductDetails(){
    const params = useParams<{productId:string}>();
    const navigate = useNavigate();
    const productDetailsQuery =  useQuery(['product', params.productId], async()=>{
        const {data } = await axiosInstance.get<IProduct>(`/products/${params.productId}`)
        return { ...data, imgUrl: data?.imgUrl ? `https://lambdamart.in/api/shop/product/image/${data.imgUrl}` : null }
    },{
        onSuccess:(data)=>{
            console.log(data)
        },
        onError:(error)=>{
            console.log(error)
        }
    })

    return(<div>
         <section className={"mt-10 container mx-auto px-28"}>
            <div className="flex justify-between mb-3">
                <h3 className={"font-semibold text-xl mb-5 flex items-baseline"}> <button className="mr-3 block " onClick={()=>navigate(-1)}><BiArrowBack/></button> <span className="block">Products Details</span></h3>
            </div>
            {
                productDetailsQuery?.isLoading ? <>loading</> : productDetailsQuery?.isError ? <>failed to load data</> :  <>
                    {
                        <div className="border rounded-md flex flex-col p-5" >
                            {
                                productDetailsQuery?.data?.imgUrl ? <img src={productDetailsQuery?.data?.imgUrl} alt="Product"  className="h-[300px] w-[300px] rounded-md" /> : 
                                <div className="h-[150px] w-[150px] rounded-md place-items-center text-5xl bg-sky-100 text-sky-600 grid">{productDetailsQuery?.data?.name?.slice(0,1)}</div>
                            }
                            <div className="mt-5">
                                <h4 className="font-semibold text-2xl">{productDetailsQuery?.data?.name}</h4>
                                <p className="text-lg text-slate-600 mt-3"><span>₹ </span>{productDetailsQuery?.data?.priceStarts} {!productDetailsQuery?.data?.isExact && <>- <span>₹ </span>{productDetailsQuery?.data?.priceEnds}</>}</p>
                                <div className="mt-3"> 
                                    <ul>
                                        {productDetailsQuery?.data?.details?.customFields.map((el, i)=><li className="inline mr-2 rounded-full bg-sky-100 px-5 font-semibold border border-sky-600 py-1 text-xs text-sky-600" key={i}>{el.name}-{el.value}</li>)}
                                    </ul>
                                </div>
                            </div>
                            
                        </div>
                    }
                </>
            }


        </section>
    </div>)
}
export default ProductDetails
import axios from 'axios';
import { environment } from '../environment/environment';
const axiosInstance = axios.create({
  baseURL: environment.url,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('a_id')}`,
  }
});
// if(localStorage.getItem('a_id')){
// axiosInstance.interceptors.request.use((config)=>{
//   console.log('request', config.headers);
//   const token = localStorage.getItem('a_id');
//   return {...config, headers: {...config.headers, Authorization: `Bearer ${token}`}};
// });
// }
export default axiosInstance;
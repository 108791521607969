import React from "react";
import Header from "./Header";
import {AiOutlineStar, AiFillStar} from "react-icons/ai";
import SearchStar from "./SearchStar";
import SearhResultCard from "./SearhResultCard";
import Footer from "../footer/Footer";
import {useSearchParams} from "react-router-dom";
import {useQuery} from "react-query";
import axios from "axios";
import { Shop } from "./shop.model";
import { categories } from "../landing/categories";

const SearchResults = () => {
    const [searchParams] = useSearchParams();
    const [searchFor, setSearchFor] = React.useState('Top Businesses');
    React.useEffect(() => {
        window.scrollTo(0, 0);
        const searchCategory = searchParams.get('cat');
        if(searchCategory){
            const found = categories.filter(category => category.id===searchCategory);
            if(found.length>0){
                setSearchFor(`Top results for ${found[0].name}`);
            }
        }
    },  [searchParams]);
    const shopSearchAPI = useQuery(['shops', searchParams.get('cat') ],async()=>{
        console.log(searchParams.get('cat'))
        const {data} = await axios.get<Shop[]>(`https://lambdamart.in/api/customer/searchShop`,{
            params :{
              searchShopFilter:{
                  loc:"13.0827,80.2707",
                  text:"",
                  limit:10,
                //   category: ''
                  category: searchParams.get('cat')
              }
            }
        });
        const x = data.filter(el=> el.status==='active').map(el=>({...el, imageurl: el.imageurl ? `https://lambdamart.in/api/vendor/shop/image/${el.imageurl}`:''}))
        return x
    },{
        retry: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            console.log(data);
        },
        onError: (err) => {
            console.log(err);
        }
    })
    return (
        <div className={"relative"}>
            <div className={"fixed top-0 left-0 right-0 z-10"}>
                <Header/>
            </div>

            <div className={"flex pt-24 min-h-screen"}>
                <div className={"border-r w-2/12"} style={{minWidth: "300px"}}>
                    <div className={"p-10"}>
                        <h3 className={"mb-5 font-bold text-cyan-700"}>Filters</h3>
                        <hr/>
                        <div className={"mb-5 mt-5"}>
                            <p className={"mb-3 font-bold text-gray-600"}>Rating</p>
                            <ul>

                                <SearchStar value={4}/>
                                <SearchStar value={3}/>
                                <SearchStar value={3}/>
                                <SearchStar value={1}/>
                            </ul>
                        </div>
                        <div>
                            <p className={"mb-3 font-bold text-gray-600"}>Distance</p>
                            <ul>
                                <li className={"mb-2"}><input type={"checkbox"} name={"distance"}/><label
                                    className={"ml-3"}>
                                    Nearby
                                </label>
                                </li>
                                <li className={"mb-2"}><input type={"checkbox"} name={"distance"}/><label
                                    className={"ml-3"}>
                                    Walking
                                </label>
                                </li>
                                <li className={"mb-2"}><input type={"checkbox"} name={"distance"}/><label
                                    className={"ml-3"}>
                                    Biking
                                </label>
                                </li>
                                <li className={"mb-2"}><input type={"checkbox"} name={"distance"}/><label
                                    className={"ml-3"}>
                                    Driving
                                </label>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
                {/*className={"container mx-auto px-28 mt-10 "}*/}
                <div className={'w-full relative '}>
                    <div className={"p-10 container mx-auto px-28"}>
                        <div className={"flex justify-between mb-10"}>
                            <h2 className={"text-3xl font-bold "}>{searchFor}</h2>
                            <div>
                                <label className={"mr-3"}>Sort By</label>
                                <select className={"text-md px-3  rounded-md"}>
                                    <option>Recommended</option>
                                    <option>Highest Rated</option>
                                    <option>Highest Reviewed</option>
                                </select>
                            </div>
                        </div>
                        <p className={"text-xl font-semibold mb-5"}>All Results</p>
                        <div>
                            {shopSearchAPI.isLoading ? <>Loading</> :
                             shopSearchAPI.isError ? <>Failed to load data</>: 
                            shopSearchAPI.data&&  shopSearchAPI.data?.length > 0 ?
                            shopSearchAPI?.data?.map(el=>{
                                return   <SearhResultCard {...el} key={el.id}/>
                            })
                           
                            :<>No Results Found</> }
                            
                        </div>

                    </div>
                    <div >
                        <Footer/>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default SearchResults;


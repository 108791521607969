import axios from "axios"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { Shop } from "../search-result/shop.model"

function About(){
    const params = useParams<{shopId:string}>()
    const {isLoading, isError, data} = useQuery(['shop', params?.shopId],async()=>{
            const {data} = await axios.get<Shop>(`https://lambdamart.in/api/vendor/shop/${params?.shopId}`)
            return {...data, imageUrl: data?.imageUrl ? `https://lambdamart.in/api/vendor/shop/image/${data.imageUrl}`:''}

    },{
        onSuccess:(data)=>{
            console.log(data)
        }
    })
    return(<div>
            <section className={"mt-10 container mx-auto px-28"}>
                    <h3 className={"font-semibold text-xl mb-5"}>Contact</h3>
                    <p>Phone: {data?.phone1}</p>
                    <p>Email:{ data?.email} </p>
                </section>
                <section className={"mt-10 container mx-auto px-28"}>
                    <h3 className={"font-semibold text-xl mb-5"}>Location</h3>
                    <p>{data?.address}, {data?.city}, {data?.state}, {data?.pincode}</p>
                </section>
    </div>)
}
export default About
import React, {ReactElement} from "react";
import {Link} from "react-router-dom";
export interface ICategory {
    name: string;
    icon : ReactElement
    id: string
}
const Category = ({name, icon, id}:ICategory) => {
  return <Link to={`/search?cat=${id}`}><div className={"rounded-md p-5 bg-gray-100 text-gray-700 hover:bg-cyan-600 hover:text-white"}>
      <p className={"mb-5 text-3xl"}>{icon}</p>
      <p className={"font-semibold"}>{name}</p>
  </div></Link>;
};
export default Category;
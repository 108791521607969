import React from 'react';
import {AiTwotoneStar} from 'react-icons/ai';
import {BsFillTelephoneFill, BsShopWindow} from 'react-icons/bs';
import {useNavigate} from "react-router-dom";
import { Shop } from './shop.model';

const SearhResultCard = (data:Shop) => {
    const navigate = useNavigate();
    return (<div className={"cursor-pointer"} onClick={()=>navigate(`/shop/${data.id}`)}>
        <div className={"border rounded-md bg-white mb-5"}>
            <div className={"flex p-5"}>
                {
                    data?.imageurl ? <img src={data.imageurl} className={"rounded-md block h-[150px] w-[150px]"} alt={"Shop Avatar"}/> : 
                    <div className='bg-gray-100 text-gray-600 grid place-content-center text-5xl h-[150px] w-[150px] rounded-md'><BsShopWindow/></div>
                }
                
                <div className={"ml-3 flex flex-col justify-between"}>
                    <div className={"text-2xl font-bold"}>
                    {data.name}
                    </div>
                    <div className={"mt-3 text-2xl text-amber-600 flex"}>
                        <AiTwotoneStar/>
                        <AiTwotoneStar/>
                        <AiTwotoneStar/>
                        <AiTwotoneStar/>
                        <AiTwotoneStar/>
                    </div>
                    <div className={"text-md mt-5 text-gray-600 text-md flex items-center"}>
                        <BsFillTelephoneFill/>
                        <span className={"block ml-1"}>{data?.phone1}</span>

                    </div>
                    <div className={"text-md text-gray-600 mt-2"}>
                        {data?.address} , {data?.city}, {data?.state}, {data?.pincode}
                    </div>
                </div>

            </div>
        </div>
    </div>)
}
export default SearhResultCard;
import React from 'react';
import Header from "../search-result/Header";
import Footer from "../footer/Footer";
import bg from "../images/bg.jpg";
import { useQuery } from 'react-query';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import axios from 'axios';
import { Shop } from '../search-result/shop.model';
import { BsShopWindow } from 'react-icons/bs';
const ShopResult = () => {
    const params = useParams<{ shopId: string }>()
    const imgurl = "https://images.unsplash.com/photo-1481026469463-66327c86e544?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=908&q=80"
    const { isLoading, isError, data } = useQuery(['shop', params?.shopId], async () => {
        const { data } = await axios.get<Shop>(`https://lambdamart.in/api/vendor/shop/${params?.shopId}`)
        return { ...data, imageUrl: data?.imageUrl ? `https://lambdamart.in/api/vendor/shop/image/${data.imageUrl}` : '' }

    }, {
        onSuccess: (data) => {
            console.log(data)
        },
        refetchOnWindowFocus: false
    })
    return (
        <div className={"relative"}>
            <div className={"fixed top-0 left-0 right-0 z-10"}>
                <Header />
            </div>
            {
                isLoading ? <></> : isError ? <></> : <>
                    <section className={"mt-24 relative"}
                        style={{ background: `url(${bg})`, height: "300px", width: "100%" }}>
                        <div className={"container mx-auto px-28"}>

                            {
                                data?.imageUrl && data?.imageUrl ? <img src={data?.imageUrl} alt="shop" className='bg-cover rounded-md absolute -bottom-10 h-[130px] w-[130px]' /> : <div style={{ height: "130px", width: "130px" }}
                                    className={'bg-gray-100  rounded-md absolute -bottom-10 grid place-content-center text-5xl text-gray-600'}>
                                    <BsShopWindow />
                                </div>
                            }


                        </div>

                    </section>
                    {/* <section className={"mt-20 container mx-auto px-28"}>
                        <h1 className={"text-3xl font-bold"}>{data?.name}</h1>
                        <hr className={"mt-5"} />
                        <div className={"mt-5"}>

                            <button className={"bg-cyan-700 text-white rounded-md p-2"}>Write a Review</button>
                            <button className={"bg-white text-cyan-700 ml-2 border border-cyan-700 rounded-md p-2"}>Add to
                                Favourite
                            </button>
                        </div>
                    </section> */}
                    <section className={"mt-20 container mx-auto px-28"}>
                        <ul>
                            <li className='inline mr-3'><NavLink to={`/shop/${params?.shopId}`}
                                className={({ isActive }) => isActive ? 'border-b-2 border-sky-600 p-3  text-sky-600' : 'text-slate-600 p-3 hover:text-sky-600 delay-100'} end>About</NavLink></li>
                            <li className='inline mr-3'><NavLink to={`/shop/${params?.shopId}/products`}
                                className={({ isActive }) => isActive ? 'border-b-2 border-sky-600 p-3  text-sky-600' : 'text-slate-600 p-3 hover:text-sky-600 delay-100'} >Products</NavLink></li>
                            <li className='inline mr-3'><NavLink to={`/shop/${params?.shopId}/reviews`}
                                className={({ isActive }) => isActive ? 'border-b-2 border-sky-600 p-3  text-sky-600' : 'text-slate-600 p-3 hover:text-sky-600 delay-100'} >Reviews</NavLink></li>
                        </ul>
                    </section>
                    <Outlet />
                </>
            }

            <div className={"mt-10"}>
                <Footer />
            </div>


        </div>
    )
}
export default ShopResult;
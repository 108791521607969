import React from 'react'
import BusinessOwnerBanner from "./BusinessOwnerBanner";
import Footer from "../footer/Footer";
import Categories from "./categories";
import Header from "./Header";
import {FiSearch} from "react-icons/fi";
import MainSearch from "../main-search/MainSearch";const Landing = () => {
    const imageURl = "https://images.unsplash.com/photo-1533777857889-4be7c70b33f7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1920&q=80"
    return (
        <div>

            <section style={{
                backgroundImage: `url(${imageURl}`,
                height: '570px',
                // backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                position: "relative",
            }}>
                <Header/>
                <div style={{position:"absolute", top:"50%", left:"50%", transform: "translate(-50%,-50%"}}>
                    <div>
                        <h1 className={"text-5xl font-bold text-center  text-white"}>Lambdamart</h1>
                        <p className='mt-3 text-white text-2xl block text-center mb-10'>Connecting people with Business</p>

                        <MainSearch/>
                    </div>

                </div>
            </section>
            <Categories/>
            <BusinessOwnerBanner/>
            <Footer/>
        </div>
    )
}
export default Landing


import React from "react";

const Footer = () => {
    return (<section className={"bg-gray-100 mt-5 py-2"}>
        <div className={"container mx-auto px-28 mt-10 pb-20"}>
            <div className={"grid grid-cols-3"}>

                <div>
                    <p className={"font-bold text-gray-800 mb-3"}>About</p>
                    <ul className={"text-gray-600"}>
                        <li className={"mb-1"}>About us</li>
                        <li className={"mb-1"}>Terms of Service</li>
                        <li className={"mb-1"}>Privacy Policy</li>
                    </ul>
                </div>
                <div>
                    <p className={"font-bold text-gray-800 mb-3"}>Lambdamart for Business</p>
                    <ul className={"text-gray-600"}>
                        <a href="https://vendor.lambdamart.in" target="_blank" className={"mb-1"}>Claim your Business Page</a>
                    </ul>
                </div>
                <div>
                    <p className={"font-bold text-gray-800 mb-3"}>Contact</p>
                    <ul className={"text-gray-600"}>
                        <li className={"mb-1"}>info@lambdamart.in</li>
                        <li className={"mb-1"}>9962519987</li>
                    </ul>
                </div>
            </div>
            <hr className={"mb-2 mt-5"}/>
            <div className={"text-gray-500 text-sm"}>&copy; 2022 lamdbdamart.in</div>
        </div>
    </section>)
}
export default Footer;
import React from "react";
import {FiSearch} from "react-icons/fi";
import {BiMap} from "react-icons/bi"
// import {categories} from "../utils/categories";
import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";
import { categories } from "../landing/categories";
const MainSearch = () => {
  const [searchCategory, setSearchCategory] = React.useState("");
  const [searchCategoryList, setSearchCategoryList] = React.useState<string[]>([]);
  const [showCategoryList, setShowCategoryList] = React.useState(false);
  const [searchParams] = useSearchParams();
  React.useEffect(() => {
    setSearchCategoryList([...categories.map(e=>e.name)]);
    const searchCategory = searchParams.get('cat');
    if(searchCategory){
      const found = categories.filter(category => category.id===searchCategory);
      if(found.length>0){
        console.log(found[0].name)
        setSearchCategory(found[0].name);

      }
    }
  }, [searchParams]);
  const navigate = useNavigate();
  const onCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCategory(e.target.value);
    if(e.target.value === "") {
      setSearchCategoryList([...categories.map(el=>el.name)]);

    } else {
      // filter a similar value from the list
      setSearchCategoryList(categories.map(e=>e.name).filter(category => category.toLowerCase().includes(e.target.value)));
    }
    setShowCategoryList(true)
  };
  const onSearchBtnClick = () => {
   
   
    const selected = categories.filter(cat=> cat.name===searchCategory)
     const params = { cat: selected[0].id };
    navigate({
      pathname: '/search',
      search: `?${createSearchParams(params)}`,
    });
  };
  return <div>
    <div className={"flex items-center"}>

    <div className={'bg-white py-3 px-4 border flex-none rounded-l bg-slate-200 text-slate-700 flex items-center'}><BiMap/><span className="ml-2">Select Location</span></div>
      <input className={"px-4 py-3 block border rounded-r"} value={"chennai"}/>
      <div className={'bg-white py-3 px-4 border ml-3 bg-slate-200 text-slate-700 rounded-l'}>Find</div>
      <div className={"relative"}>
        <input className={"px-4 py-3 block border"} value={searchCategory} onChange={onCategoryChange} onClick={()=>setShowCategoryList(true)}
               // onBlur={()=>setShowCategoryList(false)}
        />
        {showCategoryList && <div className={"absolute shadow-md top-12 bg-white w-full rounded-b"}>
          <ul>
            {searchCategoryList.map((category, index) => {
              return <li className={"cursor-pointer py-2 px-3 hover:bg-gray-50"} key={category} onClick={()=>{
                setShowCategoryList(false)
                setSearchCategory(category)}
              }>{category}</li>
            })}
          </ul>
        </div>}
      </div>


      <button className={"px-4 py-4 block bg-cyan-700 text-white rounded-r-md"} onClick={onSearchBtnClick}><FiSearch/></button>
    </div>
  </div>;
};
export default MainSearch;
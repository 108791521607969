import React from "react";
import Category from "./category";
import {CgSmartHomeRefrigerator} from "react-icons/cg";
import {RiEBikeLine, RiMusic2Line} from "react-icons/ri";
import {BiRadio, BiBasketball,BiCar} from "react-icons/bi";
import {IoFastFoodOutline, IoRestaurantOutline, IoShirtOutline} from "react-icons/io5"
import {AiOutlineMedicineBox} from "react-icons/ai";
import {GoHome, GoBook} from "react-icons/go";
export const categories = [
    { name: 'Home Appliances', icon: <CgSmartHomeRefrigerator/> ,id:"c644da80-cbd0-11ec-aca5-0bf33054d103"},
    { name: 'Four Wheeler', icon: <BiCar/>, id:"fece6ce0-cbd0-11ec-aca5-0bf33054d103" },
    { name: 'Two Wheeler', icon: <RiEBikeLine/>, id:"041a0420-cbd1-11ec-aca5-0bf33054d103"},
    { name: 'Electronics', icon:<BiRadio/>, id:"0d512d70-cbd1-11ec-aca5-0bf33054d103"},
    { name: 'Food & Beverages', icon:<IoFastFoodOutline/>, id:"180050c0-cbd1-11ec-aca5-0bf33054d103"},
    { name: 'Sports', icon: <BiBasketball/>, id:"1d34dd90-cbd1-11ec-aca5-0bf33054d103"},
    { name: 'Healthcare', icon: <AiOutlineMedicineBox/>,id:"232196d0-cbd1-11ec-aca5-0bf33054d103"},
    { name: 'Restaurant', icon: <IoRestaurantOutline/>, id:"29639c00-cbd1-11ec-aca5-0bf33054d103"},
    { name: 'Home Made products', icon: <GoHome/>, id:"37f96f60-cbd1-11ec-aca5-0bf33054d103"},
    { name: 'Musical', icon: <RiMusic2Line/>, id:"3ef21a60-cbd1-11ec-aca5-0bf33054d103"},
    { name: 'Clothing', icon:<IoShirtOutline/>, id:"433d0a80-cbd1-11ec-aca5-0bf33054d103"},
    { name: 'Tuition', icon:<GoBook/>, id:"4d10e900-cbd1-11ec-aca5-0bf33054d103"},
]
const Categories = ()=>{

    return(<section className={"container mx-auto px-28 my-20 "}>
        <p className={"text-center text-3xl text-cyan-700 mb-10"}>Browse Businesses by Category</p>
        <div className={"grid grid-cols-4 gap-3"}>
            {categories.map((category, index)=>{
                return <Category key={index} name={category.name} icon={category.icon} id={category.id}/>
            })}
        </div>
    </section>)
}
export default Categories;
import axios from "axios"
import { useState } from "react"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import { Shop } from "../search-result/shop.model"
export interface IDetails {
   customFields:CustomFields[]
}
export interface CustomFields{
    name: string;
    value: string;
}
export interface IProduct {
    id: string;
    name: string;
    isExact: boolean;
    priceStarts: number;
    priceEnds: number;
    details: IDetails;
    imgUrl: string | null;
    vendorshopId: string;
    createdAt?: any;
    modifiedAt?: any;

}
function Products() {
    const [productSearch, setProductSearch] = useState('')
    const params = useParams<{ shopId: string }>()
    const navigate = useNavigate()
    const { isLoading, isError, data } = useQuery(['products', params?.shopId, productSearch], async () => {
        const { data } = await axios.get<IProduct[]>(`https://lambdamart.in/api/vendorshops/${params?.shopId}/products`, {
            params: {
                filter: {
                    where: {
                        and: [{
                            or: [
                                {
                                    name: {
                                        ilike: `%${productSearch}%`
                                    }
                                }
                            ]
                        }]
                    }
                }
            }
        })
        return data.map(el => ({ ...el, imgUrl: el?.imgUrl ? `https://lambdamart.in/api/shop/product/image/${el.imgUrl}` : null }))

    }, {
        onSuccess: (data) => {
            console.log(data)
        },
        refetchOnWindowFocus: false,

    },
    )
    return (<div>
        <section className={"mt-10 container mx-auto px-28"}>
            <div className="flex justify-between mb-3">
                <h3 className={"font-semibold text-xl mb-5"}>Products</h3>
                <input type="search" placeholder="search product" className="border rounded-sm p-3"
                    onChange={(event) => setProductSearch(event.target.value)}
                    value={productSearch} />
            </div>
            {
                isLoading ? <>loading</> : isError ? <>failed to load data</> : data?.length === 0 ? <>No products found</> : <>
                    {
                        data?.map(el => <div className="border rounded-md flex p-5 cursor-pointer" key={el.id} onClick={()=>navigate(`${el.id}`)}>
                            {
                                el.imgUrl ? <img src={el.imgUrl} alt="Product"  className="h-[150px] w-[150px] rounded-md" /> : 
                                <div className="h-[150px] w-[150px] rounded-md place-items-center text-5xl bg-sky-100 text-sky-600 grid">{el.name.slice(0,1)}</div>
                            }
                            <div className="ml-3">
                                <h4 className="font-semibold text-2xl">{el.name}</h4>
                                <p className="text-lg text-slate-600 mt-3"><span>₹ </span>{el.priceStarts} {!el.isExact && <>- <span>₹ </span>{el.priceEnds}</>}</p>
                                {/* <div className="mt-3"> 
                                    <ul>
                                        {el.details?.customFields.map((el, i)=><li className="inline mr-2 rounded-full bg-sky-100 px-5 font-semibold border border-sky-600 py-1 text-xs text-sky-600" key={i}>{el.name}-{el.value}</li>)}
                                    </ul>
                                </div> */}
                            </div>
                            
                        </div>)
                    }
                </>
            }


        </section>
    </div>)
}
export default Products